<template>
  <div class="logs-filter">
    <v-text-field
      solo
      hide-details
      single-line
      placeholder="Search license key, user"
      v-model="q"
      @keydown="searchInput"
    >

    </v-text-field>
    <v-dialog
      scrollable
      v-model="dialog"
      max-width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="btn-no-active"
          title="More filter options"
          v-bind="attrs"
          v-on="on"
          >
            <v-icon>mdi-filter-menu-outline</v-icon>
          </v-btn>
      </template>
      <v-card>
        <v-card-title>Filter Logs By Date</v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row no-gutters>
            <v-col>
              <v-card-text>
                <div>
                  <v-text-field
                    ref="from_date_field"
                    label="From Date:"
                    type="date"
                    hide-details="auto"
                    v-model="from_date"
                  >
                  </v-text-field>
                </div>
              </v-card-text>
              <v-card-text>
                <div>
                  <v-text-field
                    ref="to_date_field"
                    label="To Date:"
                    type="date"
                    hide-details="auto"
                    v-model="to_date"
                  >
                  </v-text-field>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="closeDialog" :disabled="!canFilter" >
            Done
          </v-btn>
          <v-btn @click="cancelDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import { mapActions, mapGetters } from 'vuex'
  import moment from 'moment'

  let prev_q = ''

  export default{
    data: () => ({
      dialog: 0,
      q: '',
      from_date: null,
      to_date: null,
      canFilter: false
    }),
    mounted(){
      let {q, from_date, to_date } = this.transferLogsFilter
      this.from_date = from_date
      this.to_date = to_date
      this.q = q
    },
    methods: {
      reset(){
        this.q = '',
        this.from_date = null
        this.to_date = null
        this.updateValue()
      },
      closeDialog(){
        this.dialog = 0
        this.updateValue()
      },
      cancelDialog(){
        this.dialog = 0
        this.reset()
      },
      searchInput(e){
        if(this.q && (e.key === 'Enter' || e.keyCode === 13)){
          this.updateValue()
        }else if(!this.q && prev_q){
          this.reset()
          this.updateValue()
        }else if(this.q && (e.key === 'Escape' || e.keyCode === 227 )){
          this.q = ''
          this.updateValue()
        }

        prev_q = this.q
      },
      updateValue(){
        const new_from_date  = this.from_date ? moment(this.from_date).startOf('day').toDate() : null
        const new_to_date = this.to_date ? moment(this.to_date).endOf('day').toDate() : null
        this.setTransferLogsFilter({
          q: this.q.trim(),
          from_date: new_from_date,
          to_date: new_to_date
        })
      },

      ...mapActions([
          'setTransferLogsFilter'
        ])
    },
    computed: {
      ...mapGetters(['transferLogsFilter'])
    },

    watch: {
      from_date(v){
        if((v && v.length > 0) && this.to_date){
          this.canFilter = true
        }else{
          this.canFilter = false
        }
      },
      to_date(v){
        if((v && v.length > 0) && this.from_date){
          this.canFilter = true
        }else{
          this.canFilter = false
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
  .logs-filter{
    display: flex
  }
</style>