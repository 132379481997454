<template>
  <dashboard-layout>
    <template slot="heading">
      <v-toolbar-title class="d-none d-md-block">
        License Transfer Logs
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <transferLogsFilter/>
    </template>
    <template slot="content" class="mt-5">
      <v-alert v-if="error" rounded="0" type="error">{{error}}</v-alert>
      <v-alert
        rounded="0"
        class="text-center"
        type="info"
        v-if="!logs.length && !loading && !error"
      >
        No data to show.
      </v-alert>
      <loading-circular v-if="loading"></loading-circular>
      <v-container v-if="logs.length">
        <v-row class="mt-5">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="logs"
              :items-per-page="10"
              class="elevation-1 table"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </dashboard-layout>
</template>

<script>
  import TransferLogsFilter from '@/views/dashboard/license-transfer-logs/TransferLogsFilter'
  import { mapGetters, mapActions } from 'vuex'

  import {fetchLicenseTranferLogs} from '@/services/http/license_transfers'
  import moment from 'moment'

  export default{
    name: 'LicenseTransferLogs',
    components: {
      TransferLogsFilter
    },
    data(){
      return{
        headers: [
          {
            text: 'License Key',
            align: 'start',
            sortable: false,
            value: 'licenseKey'
          },
          {
            text: "License Type",
            value: "licenseType",
            sortable: false
          },
          {
            text: 'Transfer To',
            sortable: false,
            value: 'transferTo'
          },
          {
            text: 'Status',
            sortable: false,
            value: 'status'
          },
          {
            text: 'Date',
            value: 'created_at'
          },

        ],
        logs: [],
        error: null,
        loading: true
      }
    },
   created(){
    this.reset()
    this.load()
   },
   destroyed(){
    this.reset()
    this.resetTransferLogsFilter()
   },
   methods: {
    reset(){
      this.loading = true
      this.logs = []
    },
    async load(){
      try{
        this.loading = true
        let {q, from_date, to_date} = this.transferLogsFilter
        const res = await fetchLicenseTranferLogs({q, from_date, to_date})
        res.data.forEach(d => {
          this.logs.push({
            licenseKey: d.product_key.code,
            licenseType: d.product_key.sku_str.toUpperCase(),
            transferTo: d.user.first_name + ' ' + d.user.last_name + ` (${d.user.email})`,
            created_at: moment(d.created_at).format('MMM DD, YYYY @ hh:mm:ss a'),
            status: d.status.toUpperCase()
          })
        })
      } catch (e){
        this.error = e
      } finally {
        this.loading = false
      }
    },
      ...mapActions(['resetTransferLogsFilter'])
   },
   computed: {
      ...mapGetters(['transferLogsFilter'])
   },
   watch: {
    transferLogsFilter(){
      this.reset()
      this.load()
    }
   }
  }
</script>
